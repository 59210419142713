import { defineMessages } from "react-intl";
import {
  BUSINESS_MODEL_ID,
  COMPETITIVE_ANALYSIS,
  KPIS,
  getToolCategories,
  templateTools,
} from "../constants/tools.constants";
import * as arrayUtils from "./ArrayUtils";
import * as dateUtils from "./DateUtils";
import * as mathUtils from "./MathUtils";
import * as stringUtils from "./StringUtils";
import * as administrationUtils from "./administration";
import * as commentUtils from "./commentUtils";
import { customCenaryName, translateQuestionName } from "./cpAnalysis/display";
import * as customHooks from "./customHooks";
import * as entityUtils from "./entityUtils";
import * as formValidation from "./formValidation";
import * as insightMaturityUtils from "./insightMaturityUtils";
import * as memberUtils from "./memberUtils";
import * as okrUtils from "./okrUtils";
import * as peopleEvaluationUtils from "./peopleEvaluationUtils";
import * as projectPortfolio from "./projectEvaluation";
import * as projectPrioritization from "./projectPrioritization";
import * as routeUtils from "./routeUtils";
import * as sceneriesUtils from "./sceneriesUtils";
import * as swotUtils from "./swotUtils";
import * as tableUtils from "./tableUtils";
import * as translationUtils from "./translationUtils";
import * as validations from "./validations";

const { translatedText, getToolIntlSlug } = translationUtils;

const messages = defineMessages({
  "global.cenary": { id: "global.cenary" },
});

const customUtils = {
  ...okrUtils,
  ...peopleEvaluationUtils,
  ...insightMaturityUtils,
  ...swotUtils,
  ...memberUtils,
  ...commentUtils,
  ...formValidation,
  ...dateUtils,
  ...arrayUtils,
  ...customHooks,
  ...mathUtils,
  ...stringUtils,
  ...projectPrioritization,
  ...translationUtils,
  ...sceneriesUtils,
  ...projectPortfolio,
  ...validations,
  ...entityUtils,
  ...routeUtils,
  ...tableUtils,
  ...administrationUtils,
};

const checkCustomization = (toolID) => {
  switch (
    toolID // FERRAMENTAS NÃO CUSTOMIZADAS
  ) {
    case BUSINESS_MODEL_ID:
      return false;
    default:
      return true;
  }
};

const orderByTotalPoints = (toolPoints, orderedBy) => {
  let order1 = 1;
  let order2 = -1;

  if (orderedBy === "down") {
    order1 = -1;
    order2 = 1;
  }
  toolPoints.sort((a, b) => {
    const aPoints = a.totalPoints ? a.totalPoints : 0;
    const bPoints = b.totalPoints ? b.totalPoints : 0;

    return aPoints > bPoints ? order1 : order2;
  });
  return toolPoints;
};
const orderByEvaluation = (evaluations) => {
  if (evaluations) {
    return [...evaluations.map(({ insightId }) => insightId)];
  }

  return [];
};

const orderInsights = (orderList = [], allInsights = []) => {
  let finalOrdenation = [];
  orderList.forEach((insightID) => {
    allInsights.forEach((item) => {
      if (item.insightId === insightID || item.id === insightID)
        finalOrdenation = [...finalOrdenation, item];
    });
  });

  return finalOrdenation;
};
const buildAllThemes = (
  allThemes,
  allAnswers,
  allQuestions,
  customConfig = {}
) => {
  let themes = [];

  const { currentToolType = "" } = customConfig;

  if (allThemes) {
    Object.keys(allThemes).forEach((sceneryID) => {
      if (customConfig.isOkr) {
        allThemes[sceneryID].forEach((themeInfo) => {
          let filteredAnswer = [];

          if (allQuestions[themeInfo.id]) {
            allQuestions[themeInfo.id].forEach((questionInfo) => {
              if (allAnswers[questionInfo.id]) {
                filteredAnswer = allAnswers[questionInfo.id];
              }
            });
          }

          if (filteredAnswer.length) {
            themes = [...themes, themeInfo];
          }
        });
      } else if (customConfig.isCompetitiveAnalysis) {
        allThemes[sceneryID].forEach((themeInfo) => {
          const { cenary } = themeInfo;
          const { name } = cenary;

          if (name === currentToolType.toUpperCase()) {
            themes.push(themeInfo);
          }
        });
      } else {
        themes = [...themes, ...allThemes[sceneryID]];
      }
    });
  }

  return themes;
};
const getFiledStatusByInsightId = (insightId, answers) => {
  let finalFiledStatus = null;
  if (answers && answers.length > 0) {
    answers.forEach((item) => {
      if (item.id === insightId) {
        finalFiledStatus = item.filed;
      }
    });
  }
  return finalFiledStatus;
};

const buildSceneries = (cenaries, themes) => {
  const toolSceneries = cenaries;
  let finalSceneries = [];

  if (toolSceneries && themes) {
    toolSceneries.forEach(({ id, name }) => {
      let sceneryThemes = [];
      themes.forEach((themeInfo) => {
        if (themeInfo.cenary.id === id) {
          sceneryThemes = [
            ...sceneryThemes,
            { id: themeInfo.id, name: themeInfo.name },
          ];
        }
      });

      finalSceneries = [...finalSceneries, { id, name, themes: sceneryThemes }];
    });
  }

  return finalSceneries;
};

const buildMultiSelectOptions = (listArray = [], show, customConfig = {}) => {
  let finalOptions = [];

  if (Array.isArray(listArray) && listArray.length) {
    listArray.forEach(({ id, name, filed, cenary, text }) => {
      if (show) {
        finalOptions = [...finalOptions, { value: id, text: name || text }];
      } else if (customConfig.isOkr && filed !== true) {
        const titleCenaryTheme = `${name} - ${translatedText(
          "global.cenary",
          messages
        )} ${cenary.name}`;

        finalOptions = [...finalOptions, { value: id, text: titleCenaryTheme }];
      } else if (filed !== true) {
        finalOptions = [...finalOptions, { value: id, text: name || text }];
      }
    });
  }

  return finalOptions;
};

const getSelectsList = (
  cenaries,
  allThemes,
  selectedData,
  showHidden,
  customConfig,
  allAnswers,
  allQuestions
) => {
  const { showHiddenCenarys, showHiddenThemes, showHiddenQuestions } =
    showHidden || {};
  const themes = buildAllThemes(
    allThemes,
    allAnswers,
    allQuestions,
    customConfig
  );
  const allSceneriesThemes = buildSceneries(cenaries, themes);
  const sceneriesList = buildMultiSelectOptions(cenaries, showHiddenCenarys);
  const responsiblesSelectList = [];
  const themesList = buildMultiSelectOptions(
    themes,
    showHiddenThemes,
    customConfig
  );

  let finalThemesList = [];
  let finalThemes = [];

  if (selectedData && selectedData.sceneries) {
    // Filtrar lista de temas por cenérios selecionados
    selectedData.sceneries.forEach((sceneryID) => {
      allSceneriesThemes.forEach((sceneryInfo) => {
        if (sceneryID === sceneryInfo.id) {
          sceneryInfo.themes.forEach((themeInfo) => {
            finalThemes = [...finalThemes, themeInfo.id];
          });
        }
      });
    });
  }

  finalThemes.forEach((themeID) => {
    themesList.forEach((themeInfo) => {
      if (themeInfo.value === themeID) {
        finalThemesList = [...finalThemesList, themeInfo];
      }
    });
  });

  const questions = selectedData?.themes?.reduce((acc, theme) => {
    if (allQuestions && allQuestions[theme]) {
      return acc.concat(allQuestions[theme]);
    }

    return acc;
  }, []);

  const questionsList = buildMultiSelectOptions(questions, showHiddenQuestions);

  return {
    sceneries: sceneriesList,
    themes: finalThemesList,
    questions: questionsList,
    responsibles: responsiblesSelectList,
  };
};

const checkDisplayColumn = (labelSlug, selectedHeadLabels) => {
  let display = false;

  if (selectedHeadLabels && selectedHeadLabels.length) {
    selectedHeadLabels.forEach((selectedVal) => {
      if (labelSlug === selectedVal) display = true;
    });
  }

  return display;
};

const renderDefaultColumnLabel = (
  intl,
  globalMessages,
  selectedHeadLabels = false
) => {
  const checkDisplayTab = (slug) => {
    return !!(
      !selectedHeadLabels || checkDisplayColumn(slug, selectedHeadLabels)
    );
  };

  const displayCols = [];

  if (checkDisplayTab("scenerie_label")) {
    displayCols.push(intl.formatMessage(globalMessages.cenary));
  }

  if (checkDisplayTab("theme_label")) {
    displayCols.push(intl.formatMessage(globalMessages.theme));
  }

  if (checkDisplayTab("question_label")) {
    displayCols.push(intl.formatMessage(globalMessages.question));
  }

  if (checkDisplayTab("insight_label")) {
    displayCols.push(intl.formatMessage(globalMessages.insight));
  }

  return displayCols.map((label) => (
    <th align="left" key={label}>
      {label}
    </th>
  ));
};

export const getToolEntityText = (toolId, entity, text) => {
  if (toolId === COMPETITIVE_ANALYSIS) {
    if (entity === "cenary") return customCenaryName(text, toolId);
    if (entity === "question") return translateQuestionName(text, toolId);
  }

  return text;
};

const getToolCategoryByToolId = (toolId, toolCategories = []) => {
  const filterCategory = toolCategories.filter(
    (categoryInfo) => categoryInfo.tools.indexOf(toolId) > -1
  );

  if (filterCategory[0]) {
    return filterCategory[0];
  }

  return {};
};

const getToolCategoryUpdatedInfo = (toolInfo) => {
  const toolCategory = getToolCategoryByToolId(
    toolInfo.id,
    getToolCategories()
  );
  const toolTranslationId = getToolIntlSlug(toolInfo.id);

  return {
    ...toolInfo,
    type: toolCategory.value,
    description: translatedText(toolTranslationId, {
      [toolTranslationId]: { id: toolTranslationId },
    }),
  };
};

const getFilteredTreeList = (workspacesTreeList, treeFilter = {}) => {
  let workspaces = [];
  let tools = [];
  let sceneries = [];
  let themes = [];
  let questions = [];
  let insights = [];

  workspacesTreeList.forEach((workspaceInfo) => {
    if (
      !treeFilter.workspaces ||
      treeFilter.workspaces.indexOf(workspaceInfo.id) === -1
    ) {
      workspaces = [
        ...workspaces,
        { id: workspaceInfo.id, title: workspaceInfo.text },
      ];

      const workspaceTools =
        workspaceInfo.tools && workspaceInfo.tools.length > 0
          ? workspaceInfo.tools
          : [];
      workspaceTools.forEach((toolInfo) => {
        if (!treeFilter.tools || treeFilter.tools.indexOf(toolInfo.id) === -1) {
          const updatedToolInfo = getToolCategoryUpdatedInfo(toolInfo.tool);
          const toolId = toolInfo.tool.id;

          tools = [
            ...tools,
            {
              parentId: workspaceInfo.id,
              id: toolInfo.id,
              title: updatedToolInfo.description,
            },
          ];

          const toolSceneries =
            toolInfo.sceneries && toolInfo.sceneries.length > 0
              ? toolInfo.sceneries
              : [];
          toolSceneries.forEach((sceneryInfo) => {
            if (
              !treeFilter.sceneries ||
              treeFilter.sceneries.indexOf(sceneryInfo.id) === -1
            ) {
              sceneries = [
                ...sceneries,
                {
                  parentId: toolInfo.id,
                  id: sceneryInfo.id,
                  title: getToolEntityText(toolId, "cenary", sceneryInfo.text),
                },
              ];

              const sceneryThemes =
                sceneryInfo.themes && sceneryInfo.themes.length > 0
                  ? sceneryInfo.themes
                  : [];
              sceneryThemes.forEach((themeInfo) => {
                if (
                  !treeFilter.themes ||
                  treeFilter.themes.indexOf(themeInfo.id) === -1
                ) {
                  themes = [
                    ...themes,
                    {
                      parentId: sceneryInfo.id,
                      id: themeInfo.id,
                      title: themeInfo.text,
                    },
                  ];

                  const themeQuestions =
                    themeInfo.questions && themeInfo.questions.length > 0
                      ? themeInfo.questions
                      : [];
                  themeQuestions.forEach((questionInfo) => {
                    if (
                      !treeFilter.questions ||
                      treeFilter.questions.indexOf(questionInfo.id) === -1
                    ) {
                      questions = [
                        ...questions,
                        {
                          parentId: themeInfo.id,
                          id: questionInfo.id,
                          title: getToolEntityText(
                            toolId,
                            "question",
                            questionInfo.text
                          ),
                        },
                      ];

                      const questionInsights =
                        questionInfo.insights &&
                        questionInfo.insights.length > 0
                          ? questionInfo.insights
                          : [];
                      questionInsights.forEach((insightInfo) => {
                        if (
                          !treeFilter.insights ||
                          treeFilter.insights.indexOf(insightInfo.id) === -1
                        ) {
                          insights = [
                            ...insights,
                            {
                              parentId: questionInfo.id,
                              id: insightInfo.id,
                              title: insightInfo.text,
                            },
                          ];
                        }
                      });
                    }
                  });
                }
              });
            }
          });
        }
      });
    }
  });

  return {
    workspaces,
    tools,
    sceneries,
    themes,
    questions,
    insights,
  };
};

const getActiveTools = (tools = []) => {
  const finalFiltered = [];

  const exceptions = [KPIS];

  if (tools && tools.length > 0) {
    tools.forEach((toolInfo) => {
      if (
        toolInfo.constructionCompleted ||
        exceptions.indexOf(toolInfo.id) > -1
      )
        finalFiltered.push(toolInfo);
    });
  }

  return finalFiltered;
};

function hasToolTemplate(toolId) {
  return templateTools.includes(toolId);
}

const utils = {
  ...customUtils,
  checkCustomization,
  orderByTotalPoints,
  orderByEvaluation,
  orderInsights,
  getSelectsList,
  checkDisplayColumn,
  renderDefaultColumnLabel,
  getFiledStatusByInsightId,
  getFilteredTreeList,
  getActiveTools,
  getToolCategoryByToolId,
  getToolCategoryUpdatedInfo,
  hasToolTemplate,
};

export default utils;
