/* eslint-disable react/prop-types */
/* eslint-disable react/sort-comp */
import { Tooltip } from "@material-ui/core";
import _ from "lodash";
import PropTypes from "prop-types";
import { Component } from "react";
import { defineMessages, injectIntl } from "react-intl";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";

import { unselectInsight } from "../../actions/insightActions";
import { redirectTo } from "../../actions/redirect";
import { fetchUserTeams } from "../../actions/teamsActions";
import {
  clearToolRelatedEntities,
  fetchToolTree,
  setSelectedTool,
} from "../../actions/toolActions";
import MainSideBarItem from "./MainSideBarItem";

import { filterDisplayWorkspaceTools } from "../../actions/customPeriodActions";
import { cleanAllLocalStorageData } from "../../actions/toolsConfigurationsActions";
import utils from "../../utils/toolUtils";

import {
  ACTION_PLAN_ID,
  COMPETITIVE_ANALYSIS,
  GOALS_AND_BONUS,
  KPIS,
  OKR_ID,
  PEOPLE_BIG_PICTURE,
  PROCESSES_BIG_PICTURE,
  PROJECT_PORTFOLIO,
  PROJECTS_INITIATIVES,
  SKILLS_ASSESSMENT,
} from "constants/tools.constants";
import {
  getSelectedCompanyStates,
  getWorkspacesStates,
} from "../customMapStates";

const { getActiveTools, getToolIntlSlug, getComparableQuery } = utils;

const messages = defineMessages({
  selectCompany: { id: "global.selectCompany" },
});

class MainSideBar extends Component {
  state = {
    allRoutes: [],
    openNavs: {},
    newPath: null,
    newRoute: null,
    selectedParent: null,
    displayTools: [],
  };

  UNSAFE_componentWillMount() {
    this.setRoutes(null, null, true);
  }

  async UNSAFE_componentWillReceiveProps({
    workspaceTools,
    workspacesData,
    intl,
  }) {
    const { selectedWorkspace } = workspacesData;
    const { selectedWorkspace: propsSelectedWorkspace } =
      this.props.workspacesData;

    const workspaceChanged =
      !_.isEqual(workspaceTools, this.props.workspaceTools) ||
      selectedWorkspace?.id !== propsSelectedWorkspace?.id;

    const doFetchFilteredTools =
      (workspaceChanged && selectedWorkspace?.id) ||
      (selectedWorkspace?.id && this.state.displayTools.length === 0);

    if (doFetchFilteredTools) {
      this.setState({
        displayTools: await filterDisplayWorkspaceTools(
          selectedWorkspace.id,
          workspaceTools
        ),
      });
    }

    if (
      workspaceChanged ||
      doFetchFilteredTools ||
      window.location.pathname !== this.props.location.pathname ||
      intl.locale !== this.props.intl.locale
    ) {
      this.setRoutes(workspacesData, intl);
    } else {
      this.setRoutes();
    }
  }

  componentDidUpdate(prevProps) {
    const { selectedTool } = this.props;

    if (selectedTool?.id && selectedTool?.id !== prevProps.selectedTool?.id) {
      this.fetchEssencialData();
    }

    if (!_.isEqual(prevProps.tools, this.props.tools)) this.setRoutes();
  }

  fetchEssencialData = () => {
    const { selectedTool, location = {} } = this.props;
    const compareLocation = location.pathname || "";

    if (
      getComparableQuery(compareLocation).match("/TOOL/") ||
      getComparableQuery(compareLocation).match("/ANALYTICS/") ||
      compareLocation === "/"
    ) {
      this.props.fetchToolTree(selectedTool.id, selectedTool.tool?.id);
    }
  };

  setRoutes(workspacesData, intl) {
    //const overviewRouteInfo = this.getOverviewRouteInfo();
    const { displayTools } = this.state;
    const workspaceRoutesInfo = this.getWorkspaceRouteInfo();
    const selectedWorkspaceRouteInfo = this.getSelectedWorkspaceRouteInfo(
      displayTools,
      workspacesData,
      intl
    );

    const allRoutes = [workspaceRoutesInfo, ...selectedWorkspaceRouteInfo];

    let { selectedParent } = this.state;
    let selectedIDs = [];
    allRoutes.forEach(({ id, path, parentID, routes }) => {
      let isSelectedRoute = false;

      if (this.checkRouteActive(path)) isSelectedRoute = true;

      if (isSelectedRoute || this.checkChildrenSelected(routes)) {
        selectedParent = parentID;
        selectedIDs = [...selectedIDs, id];
      }
    });

    let openNavs = {};
    allRoutes.forEach(({ id }) => {
      if (id) {
        const isOpen = !!(
          this.state.openNavs[id] || selectedIDs.indexOf(id) > -1
        );
        openNavs = {
          ...openNavs,
          [id]: isOpen,
        };
      }
    });

    this.setState({ allRoutes, openNavs, selectedParent });
  }

  handleToggle = (
    slugID,
    path,
    customFunction,
    parentID = null,
    active = true
  ) => {
    const { history } = this.props;
    const { openNavs } = this.state;
    let newOpenNavs = slugID ? {} : openNavs;

    if (slugID) {
      Object.keys(openNavs).forEach((slugKey) => {
        const actualOpenState = openNavs[slugKey];
        const sameKey = slugKey === slugID;

        newOpenNavs = {
          ...newOpenNavs,
          [slugKey]: sameKey ? !actualOpenState : false,
        };
      });
    }

    if (!this.checkRouteActive(path) && active) history.push(path);

    if (typeof customFunction === "function") customFunction();
    this.setState({
      openNavs: newOpenNavs,
      selectedParent: parentID,
    });
  };

  getOverviewRouteInfo = () => {
    const completedTools = getActiveTools(this.props.tools);

    return {
      id: "sidebarOverview",
      label: "Overview",
      active: false,
      icon: "fa fa-globe",
      clickFunction: () =>
        this.handleToggle("sidebarOverview", "/overview", null, null),
      routes: [
        ...completedTools.map((toolInfo) => {
          const toolRoute = `/analytics/${toolInfo.id}`;
          return {
            path: toolRoute,
            icon: "fas fa-chart-bar",
            label: `${toolInfo.description} Analytics`,
            clickFunction: () => this.handleToggle(null, toolRoute),
          };
        }),
      ],
    };
  };

  getWorkspaceRouteInfo = () => {
    const { selectedCompany = {} } = this.props;

    const { id: selectedCompanyId = null } = selectedCompany || {};

    if (!selectedCompanyId) return {};

    return {
      id: "sidebarWorkspaces",
      label: "Workspaces",
      path: "/workspaces",
      icon: "fa fa-briefcase",
    };
  };

  getCompanyRoutesInfo = () => {
    return {
      id: "sidebarCompanies",
      label: "Companies",
      path: "/companies",
      icon: "fas fa-city",
    };
  };

  getGroupedToolsRoutes = (toolRoutes) => {
    const groups = {
      1: {
        id: "toolGroupCorporate",
        label: "Rookau Corporate",
        path: "/corporate",
        icon: "fas fa-building",
        toolsIds: [
          COMPETITIVE_ANALYSIS,
          KPIS,
          PROCESSES_BIG_PICTURE,
          PROJECTS_INITIATIVES,
          PROJECT_PORTFOLIO,
          ACTION_PLAN_ID,
        ],
      },
      2: {
        id: "toolGroupPeople",
        label: "Rookau People",
        path: "/people",
        icon: "fas fa-users",
        toolsIds: [
          OKR_ID,
          PEOPLE_BIG_PICTURE,
          GOALS_AND_BONUS,
          SKILLS_ASSESSMENT,
          ACTION_PLAN_ID,
        ],
      },
    };
    return Object.keys(groups).map((key) => {
      const group = groups[key];
      const { toolsIds } = group;
      return {
        ...group,
        child: true,
        routes:
          toolRoutes &&
          toolRoutes.filter((route) => toolsIds.includes(route.toolId)),
      };
    });
  };

  getSelectedWorkspaceRouteInfo = (tools, workspacesData, intl) => {
    const { formatMessage } = intl || {};
    const { selectedWorkspace } = workspacesData || {};

    const customFunction = (tool) => {
      this.props.cleanAllLocalStorageData();
      this.props.setSelectedTool(tool);
    };

    let toolRoutes = [];
    tools?.forEach((selectedTool) => {
      if (selectedTool?.id) {
        const toolPath = `/tool/${selectedTool?.id}`;
        const toolTranslationSLug = getToolIntlSlug(selectedTool?.tool?.id);

        const routeInfo = {
          clickFunction: () =>
            this.handleToggle(
              null,
              toolPath,
              () => customFunction(selectedTool),
              "sidebarWorkspaces"
            ),
          path: toolPath,
          icon: selectedTool.tool.icon,
          label:
            typeof formatMessage === "function" &&
            intl.formatMessage({ id: toolTranslationSLug }),
          parentID: "sidebarWorkspaces",
          toolId: selectedTool?.tool?.id,
        };

        toolRoutes = [...toolRoutes, routeInfo];
      }
    });

    if (selectedWorkspace && selectedWorkspace.id) {
      const groupedToolsRoutes = this.getGroupedToolsRoutes(toolRoutes);

      return [
        {
          id: "sidebarSelectedWorkspace",
          label: selectedWorkspace.name,
          path: "/workspace",
          icon: "fas fa-clipboard-list",
          parentID: "sidebarWorkspaces",
          child: true,
          lastChild: true,
        },
        ...groupedToolsRoutes,
      ];
    }
    return [];
  };

  checkRouteActive(compareRoute) {
    const { history } = this.props;
    return compareRoute === history.location.pathname;
  }

  checkChildrenSelected = (routes) => {
    let haveChildrenSelected = false;
    if (routes) {
      routes.forEach(({ path }) => {
        if (this.checkRouteActive(path)) haveChildrenSelected = true;
      });
    }
    return haveChildrenSelected;
  };

  render() {
    const { selectedCompany = {}, intl } = this.props;
    const { allRoutes, openNavs, selectedParent } = this.state;

    const userMenu = () => {
      const { id: selectedCompanyId = false } = selectedCompany;

      if (!selectedCompanyId) return guestMenu;

      return (
        <>
          <ul className="sidebar-menu" data-widget="tree">
            {allRoutes.map(
              (
                {
                  id,
                  path,
                  icon,
                  label,
                  routes,
                  child,
                  lastChild,
                  parentID,
                  active,
                },
                index
              ) => {
                const isSelectedParent = selectedParent === id;
                const haveChildrenSelected = this.checkChildrenSelected(routes);
                return (
                  <MainSideBarItem
                    id={id}
                    active={this.checkRouteActive(path) || haveChildrenSelected}
                    path={path}
                    iconClass={icon}
                    label={label}
                    open={openNavs[id]}
                    key={index}
                    onClick={() =>
                      this.handleToggle(id, path, null, parentID, active)
                    }
                    child={child}
                    lastChild={lastChild}
                    isSelectedParent={isSelectedParent}
                    tooltipText={label}
                  >
                    {routes &&
                      routes.map((subRoute, index) => (
                        <MainSideBarItem
                          key={index}
                          active={this.checkRouteActive(subRoute.path)}
                          path={subRoute.path}
                          iconClass={subRoute.icon}
                          label={subRoute.label}
                          onClick={
                            subRoute.clickFunction && subRoute.clickFunction
                          }
                          tooltipText={
                            subRoute.toolId === KPIS
                              ? "KPI-OKR-BSC"
                              : subRoute.label
                          }
                          showTooltip={subRoute.toolId === KPIS}
                        />
                      ))}
                  </MainSideBarItem>
                );
              }
            )}
          </ul>
        </>
      );
    };

    const guestMenu = (
      <ul className="sidebar-menu" data-widget="tree">
        <Link to="/companies">
          <li className="header text-center" style={{ padding: "20px 10px" }}>
            {intl.formatMessage(messages.selectCompany)}
          </li>
        </Link>
      </ul>
    );

    return (
      <aside className="main-sidebar" id="main-menu">
        <section className="sidebar">
          <div>{userMenu()}</div>
          <Link to="/workspace">
            <Tooltip title="Aprenda mais">
              <i
                className="far fa-question-circle"
                onClick={() => this.props.redirectTo(4)}
                style={{
                  position: "fixed",
                  zIndex: 9990,
                  bottom: "29px",
                  color: "#fff",
                  left: "20px",
                  cursor: "pointer",
                  fontSize: "16px",
                  padding: "6px",
                }}
              />
            </Tooltip>
          </Link>
          <div id="chat-widget" className="chat-widget" />
        </section>
      </aside>
    );
  }
}

function mapStateToProps(state) {
  const { selectedCompany = null } = getSelectedCompanyStates(state);
  const { tools, selectedTool, companyData } = state;
  const { workspacesData = {}, workspaceTools = [] } =
    getWorkspacesStates(state);

  return {
    workspacesData,
    workspaceTools,
    tools,
    selectedCompany,
    selectedTool,
    companyLogo: companyData.selectedCompany?.logo,
  };
}

MainSideBar.propTypes = {
  workspaceTools: PropTypes.array.isRequired,
};

export default injectIntl(
  withRouter(
    connect(mapStateToProps, {
      fetchUserTeams,
      unselectInsight,
      redirectTo,
      setSelectedTool,
      cleanAllLocalStorageData,
      clearToolRelatedEntities,
      fetchToolTree,
    })(MainSideBar)
  )
);
