/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-use-before-define */
import React, { useState, useEffect, useRef } from "react";
import { defineMessages } from "react-intl";
import { connect } from "react-redux";
import styled from "styled-components";
import ReactToPdf from "react-to-pdf";
import moment from "moment";

import GoalEvaluation from "../insight/GoalEvaluation";
import SimpleLegend from "../../SimpleLegend";
import ActionPlan from "../insight/ActionPlan";
import FormDialog from "../../FormDialog";

import { getIndicatorTargetOptions } from "../../../../constants/kpis";
import {
  displayTransformValue,
  getHitTargetInfo,
} from "../../../../utils/kpis/display";
import { getOnlySublevelArrays } from "../../../../utils/ArrayUtils";
import { translatedText } from "../../../../utils/translationUtils";
import { getSingleIndicatorResults } from "../../../../utils/kpis/calc2";
import {
  getDisplayTargets,
  getLastPeriodDeviation,
} from "../../../../utils/kpis/calc";
import HitTarget from "../../HitTarget";
import { stringToSlug } from "../../../../utils/StringUtils";
import { fetchInsightComments } from "../../../../actions/insightTimelineActions";
import Chart from "./DetailedChart";

const clickableStyles = {
  position: "absolute",
  top: "0",
  left: "0",
  width: "100%",
  height: "100%",
  cursor: "pointer",
  zIndex: "100",
};

const messages = defineMessages({
  acp_text_show_details: { id: "acp_text_show_details" },
  acp_text_hide_details: { id: "acp_text_hide_details" },
  acp_text_show_actions_plan: { id: "acp_text_show_actions_plan" },
  kpi_table_text_prev_period: { id: "kpi_table_text_prev_period" },
});

const translation = (id) => translatedText(id, messages);

const OverviewGrid = styled.div`
  width: 100%;
  display: flex;
  padding: 15px;
`;

const Card = styled.div`
  margin: 2.5%;
  width: 15%;
  padding: 5px;
  border: 1px solid #cfcfcf;
  border-radius: 4px;
  background-color: #f1f1f1;
  font-weight: bold;

  h4 {
    font-weight: normal;
    font-size: 14px;
  }
`;

const defaultShowLines = {
  NOT_HIT: true,
  GATE: true,
  APPROPRIATED: true,
  EXCEEDED: true,
  LAST_PERIOD: false,
};

function getRelatedInsight(relatedInsightIds, filteredInsights) {
  const filtered = filteredInsights.filter(
    ({ id }) => relatedInsightIds.indexOf(id) > -1,
  );
  if (filtered[0]) return filtered[0];
  return {};
}

const ComposedPeriodsChart = ({
  goalInfo,
  actualTargetValues,
  goalTargetConfigurations,
  allAnswers,
  selectedTool,
  zeroWhenNegative,
  kpiAdministration,
}) => {
  const targetRef = useRef();
  const [graphModal, setGraphModal] = useState(false);
  const [details, setDetails] = useState(false);
  const [showActionPlan, setShowActionPlan] = useState(false);
  const [showLines, setShowLines] = useState(defaultShowLines);
  const [exporting, setExporting] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const { measurementUnity, target } = goalInfo;

  const onlyInsights = getOnlySublevelArrays(allAnswers);
  const insightInfo = getRelatedInsight(goalInfo.relatedInsights, onlyInsights);
  const targetOptions = getIndicatorTargetOptions();

  useEffect(() => {
    if (insightInfo && details) fetchInsightComments(insightInfo.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [insightInfo, details]);

  useEffect(() => {
    if (!graphModal) resetDetails();
  }, [graphModal]);

  const resetDetails = () => {
    setDetails(false);
    setShowActionPlan(false);
  };

  const toggleDetails = (type = "details") => {
    if (type === "details") {
      setDetails(!details);
      setShowActionPlan(false);
    } else {
      setShowActionPlan(!showActionPlan);
      setDetails(false);
    }
  };

  function toggleShowLine(lineSlug) {
    setShowLines((current) => {
      return {
        ...current,
        [lineSlug]: !current[lineSlug],
      };
    });
  }

  function handleExport(toPdf) {
    setExporting(true);
    toPdf();

    setTimeout(() => {
      setExporting(false);
    }, [3000]);
  }

  function closeGraphModal() {
    setGraphModal(false);
    setShowLines(defaultShowLines);
  }

  const {
    notStarted,
    goalPercentage,
    courseTargetsDivision,
    goalPeriodTotalEvaluation,
    lastYearCourseEvaluation,
  } = getSingleIndicatorResults(
    goalInfo,
    goalTargetConfigurations,
    zeroWhenNegative,
  );

  const { displayTotal, displayCourseTargets } = getDisplayTargets(
    goalInfo,
    goalPeriodTotalEvaluation,
    courseTargetsDivision,
  );

  const hitTargetInfo = getHitTargetInfo(
    goalPercentage,
    goalTargetConfigurations,
    notStarted,
  );

  const {
    displayDeviation: fullPeriodDeviation,
    color: fullDeviationColor,
    isPositiveByPole,
  } = getLastPeriodDeviation(
    displayCourseTargets.appropriated,
    goalPeriodTotalEvaluation,
    target,
    notStarted,
  );

  const { displayTotal: lastYearCourseTotal } = getDisplayTargets(
    goalInfo,
    lastYearCourseEvaluation,
  );

  const options = {
    orientation: "portrait",
    unit: "px",
    format: [950, 600],
  };

  const graphProps = { actualTargetValues, goalInfo, showLines, targetOptions };

  return (
    <div style={{ width: "160px", height: "60px", position: "relative" }}>
      <Chart {...graphProps} />
      {!graphModal && (
        <div
          style={clickableStyles}
          title="Ver detalhes"
          onClick={() => setGraphModal(true)}
        />
      )}
      <FormDialog
        open={graphModal}
        title={insightInfo.text}
        onCancel={() => closeGraphModal()}
        onConfirm={() => closeGraphModal()}
        confirmText="Voltar"
        bodyStyle={{ textAlign: "center" }}
        dialogClassName="composed-periods-chart-1100"
        hideCancel
      >
        <div ref={targetRef} style={{ opacity: isLoading ? 0.7 : 1 }}>
          {exporting && (
            <h2 style={{ paddingTop: "25px" }}>{insightInfo.text}</h2>
          )}
          <OverviewGrid>
            <Card>
              <h4>Período atual</h4>
              {displayTransformValue(displayTotal, measurementUnity)}
            </Card>
            <Card>
              <h4>Meta (planejado)</h4>
              {displayTransformValue(
                displayCourseTargets.appropriated,
                measurementUnity,
              )}
            </Card>
            <Card>
              <h4>Ano anterior</h4>
              {displayTransformValue(lastYearCourseTotal, measurementUnity)}
            </Card>
            <Card>
              <h4>Desvio meta</h4>
              <font style={{ color: fullDeviationColor }}>
                {isPositiveByPole ? (
                  <i className="fas fa-caret-up" />
                ) : (
                  <i className="fas fa-caret-down" />
                )}
                {` ${fullPeriodDeviation}`}
              </font>
            </Card>
            <Card>
              <h4>Score</h4>
              <HitTarget
                text={`${goalPercentage}%`}
                color={hitTargetInfo.color}
                bgColor="transparent"
                notStarted={notStarted}
                lineHeight="auto"
                barWidth="15px"
              />
            </Card>
          </OverviewGrid>
          <Chart detailed animation={false} {...graphProps} />
          {targetOptions.map((targetOptionInfo) => {
            return (
              <SimpleLegend
                key={targetOptionInfo.value}
                label={targetOptionInfo.label}
                fillColor={targetOptionInfo.color}
                styles={{
                  opacity: showLines[targetOptionInfo.value] ? 1 : 0.5,
                  cursor: "pointer",
                }}
                onClick={() => toggleShowLine(targetOptionInfo.value)}
              />
            );
          })}
          <SimpleLegend
            styles={{
              opacity: showLines.LAST_PERIOD ? 1 : 0.5,
              cursor: "pointer",
            }}
            onClick={() => toggleShowLine("LAST_PERIOD")}
            label={translation("kpi_table_text_prev_period")}
            fillColor="rgb(176,158,98)"
          />
          <div className="row">
            <div
              align="left"
              className="col-xs-12"
              style={{ marginTop: "15px" }}
            >
              {!exporting && (
                <div align="center">
                  <a
                    onClick={() => toggleDetails("details")}
                    style={{ cursor: "pointer", padding: "0px 10px" }}
                  >
                    {!details
                      ? translation("acp_text_show_details")
                      : translation("acp_text_hide_details")}
                  </a>
                  <a
                    onClick={() => toggleDetails("showActionPlan")}
                    style={{ cursor: "pointer", padding: "0px 10px" }}
                  >
                    {!showActionPlan
                      ? translation("acp_text_show_actions_plan")
                      : translation("acp_text_hide_details")}
                  </a>
                  <ReactToPdf
                    targetRef={targetRef}
                    options={options}
                    filename={`${stringToSlug(
                      insightInfo.text,
                    )}-${moment().toISOString()}.pdf`}
                  >
                    {({ toPdf }) => (
                      <a
                        onClick={() => handleExport(toPdf)}
                        style={{ cursor: "pointer", padding: "0px 10px" }}
                      >
                        Exportar <i className="far fa-file-pdf" />
                      </a>
                    )}
                  </ReactToPdf>
                </div>
              )}
              {details && (
                <GoalEvaluation
                  insight={insightInfo}
                  goalDescriptions={goalInfo}
                  setGoalDescriptions={() => {}}
                  goalTargetConfigurations={goalTargetConfigurations}
                  blockAllChanges
                  isAdmin={false}
                  exporting={exporting}
                  zeroWhenNegative={zeroWhenNegative}
                  kpiAdministration={kpiAdministration}
                  isLoading={isLoading}
                  setIsLoading={setIsLoading}
                />
              )}
              {showActionPlan && insightInfo && insightInfo.id && (
                <ActionPlan
                  insight={insightInfo}
                  selectedTool={selectedTool}
                  dragabble={false}
                  blockAllChanges
                  hideActions
                  overflowScroll
                />
              )}
            </div>
          </div>
        </div>
      </FormDialog>
    </div>
  );
};

export default connect(null, { fetchInsightComments })(ComposedPeriodsChart);
